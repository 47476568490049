import { TGenerateTemplateDto } from '@valuecase/common'
import { Button2, DottedPagination, IonIcon } from '@valuecase/ui-components'
import { AnimatePresence, motion } from 'framer-motion'
import { useCallback, useMemo, useState } from 'react'
import { TemplateGenerationPromptStage } from './stages/TemplateGenerationPromptStage'
import { TemplateGenerationReviewStage } from './stages/TemplateGenerationReviewStage'
import { useTemplateGeneration } from './context/TemplateGenerationContext'
import { useTrackEvent } from '@/mixpanel/useTrackEvent'

export const TemplateGenerationFlow = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [form, setForm] = useState<TGenerateTemplateDto>({
    context: '',
    useCase: 'sales',
  })
  const { stageTemplateRequest, startTemplateGeneration, isStaging } = useTemplateGeneration()
  const { trackEvent } = useTrackEvent()

  const steps = useMemo(
    () => [
      {
        id: 'prompt',
        component: TemplateGenerationPromptStage,
        title: 'Create Template with AI',
        titleSubtext: 'Tell us about what you want to build.',
        icon: 'c_ai-sparkles',
      },
      {
        id: 'review',
        component: TemplateGenerationReviewStage,
        title: 'Content Review',
        titleSubtext: 'Review the pages content before starting to create your template.',
        icon: 'c_ai-sparkles',
      },
    ],
    [],
  )

  const handleNext = useCallback(() => {
    if (steps[currentStep].id === 'prompt') {
      stageTemplateRequest({ ...form })
      trackEvent({
        event: 'templates-newtemplate-ai-next_page',
        eventProperties: {
          templatePrompt: form.context,
          aiTemplateUseCase: form.useCase,
        },
      })
      setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1))
    }

    if (steps[currentStep].id === 'review') {
      trackEvent({
        event: 'templates-newtemplate-ai_created',
        eventProperties: {
          templatePrompt: form.context,
          aiTemplateUseCase: form.useCase,
        },
      })
      startTemplateGeneration({ ...form })
    }
  }, [steps, currentStep, stageTemplateRequest, form, startTemplateGeneration, trackEvent])

  const handleBack = useCallback(() => {
    setCurrentStep((prev) => Math.max(prev - 1, 0))
  }, [])

  const CurrentStageComponent = useMemo(() => steps[currentStep].component, [steps, currentStep])

  const handleUpdateForm = useCallback((form: TGenerateTemplateDto) => {
    setForm(form)
  }, [])

  const nextIsDisabled = useMemo(() => {
    return (
      steps[currentStep].id === 'prompt' && (form.context?.length === 0 || form.useCase === null)
    )
  }, [steps, currentStep, form.context?.length, form.useCase])

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className='fixed inset-0 z-50 flex flex-col bg-gradient-to-br from-ai-s1-from via-ai-s1-via to-ai-s1-to'
        >
          {/* Header with close button */}
          <div className='absolute top-16 right-16 p-4 hover:cursor-pointer'>
            <IonIcon name='close' onClick={onClose} />
          </div>

          {/* Title section */}
          <div className='flex flex-col gap-2 items-center justify-center mt-16'>
            <AnimatePresence mode='wait'>
              <motion.div
                key={`icon-${currentStep}`}
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.5, opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <IonIcon name={steps[currentStep].icon} className='text-ai-s4-from w-10 h-10' />
              </motion.div>
            </AnimatePresence>

            <AnimatePresence mode='wait'>
              <motion.span
                key={`title-${currentStep}`}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                className='text-2xl font-extrabold text-grey-s6'
              >
                {steps[currentStep].title}
              </motion.span>
            </AnimatePresence>

            <AnimatePresence mode='wait'>
              <motion.span
                key={`subtext-${currentStep}`}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className='text-grey-s5 text-sm'
              >
                {steps[currentStep].titleSubtext}
              </motion.span>
            </AnimatePresence>
          </div>

          {/* Content area with fixed height and scrolling */}
          <div className='flex-1 overflow-auto px-8 h-full py-4 mx-auto w-full max-w-4xl'>
            <motion.div
              key={currentStep}
              initial={{ x: 50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -50, opacity: 0 }}
            >
              <CurrentStageComponent onUpdateForm={handleUpdateForm} form={form} />
            </motion.div>
          </div>

          {/* Bottom navigation - fixed position */}
          <div className='absolute bottom-16 left-16 right-16 flex items-center justify-between'>
            <div className='w-[160px]'>
              {steps[currentStep].id === 'review' && (
                <Button2 variant={'plain'} trailingIcon='arrow-back-outline' onClick={handleBack}>
                  Revisit Instructions
                </Button2>
              )}
            </div>
            <DottedPagination page={currentStep} pageCount={steps.length} />
            <div className='w-[160px] flex justify-end'>
              {steps[currentStep].id === 'review' ? (
                <Button2 disabled={isStaging} leadingIcon='checkmark-outline' onClick={handleNext}>
                  Create Template
                </Button2>
              ) : (
                <Button2
                  disabled={nextIsDisabled}
                  leadingIcon='arrow-forward-outline'
                  onClick={handleNext}
                >
                  Next
                </Button2>
              )}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
