import { TGenerateTemplateDto, TTemplateGenerationRequest } from '@valuecase/common'
import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useGenerateTemplate } from '../../../hooks/useGenerateTemplate'
import { usePollTemplateGenerationRequest } from '../../../hooks/usePollTemplateGenerationRequest'
import { useStageTemplateGenerationRequest } from '../../../hooks/useStageTemplateGenerationRequest'
import { TemplateGenerationFlow } from '../TemplateGenerationFlow'
import TemplateGenerationLoading from '../TemplateGenerationLoading'

interface TemplateGenerationContextType {
  isOpen: boolean
  openTemplateGeneration: () => void
  closeTemplateGeneration: () => void
  currentRequest: TTemplateGenerationRequest | null
  isPolling: boolean
  isGenerating: boolean
  isStaging: boolean
  generationData: TTemplateGenerationRequest | undefined
  stageTemplateRequest: (data: TGenerateTemplateDto) => Promise<TTemplateGenerationRequest>
  startTemplateGeneration: (payload: any) => Promise<TTemplateGenerationRequest>
}

export const TemplateGenerationContext = createContext<TemplateGenerationContextType | undefined>(
  undefined,
)

export const TemplateGenerationProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentRequest, setCurrentRequest] = useState<TTemplateGenerationRequest | null>(null)
  const [shouldPoll, setShouldPoll] = useState(false)
  const [showTemplateGenerationLoading, setShowTemplateGenerationLoading] = useState(false)

  const { isPolling, data: generationData } = usePollTemplateGenerationRequest({
    requestId: shouldPoll ? currentRequest?.id ?? null : null,
  })

  const { stageTemplateRequest: stageRequest, isStaging } = useStageTemplateGenerationRequest()
  const { generateTemplate, isGenerating } = useGenerateTemplate(currentRequest?.id ?? null)

  const openTemplateGeneration = useCallback(() => {
    setIsOpen(true)
  }, [])

  const closeTemplateGeneration = useCallback(() => {
    setIsOpen(false)
    setCurrentRequest(null)
  }, [])

  const onClose = () => {
    setShowTemplateGenerationLoading(false)
    setCurrentRequest(null)
    closeTemplateGeneration()
  }

  const stageTemplateRequest = useCallback(
    async (data: TGenerateTemplateDto) => {
      try {
        setShowTemplateGenerationLoading(false)
        setCurrentRequest(null)
        const request = await stageRequest(data, currentRequest?.id)
        setCurrentRequest(request)
        return request
      } catch (error) {
        console.error('Failed to stage template request:', error)
        throw error
      }
    },
    [currentRequest?.id, stageRequest],
  )

  const startTemplateGeneration = useCallback(
    async (payload: Parameters<typeof generateTemplate>[0]) => {
      try {
        const request = await generateTemplate(payload)
        setCurrentRequest(request)
        setShouldPoll(true)
        setIsOpen(false)
        setShowTemplateGenerationLoading(true)
        return request
      } catch (error) {
        console.error('Failed to start template generation:', error)
        setShouldPoll(false)
        setShowTemplateGenerationLoading(false)
        throw error
      }
    },
    [generateTemplate],
  )

  const value = useMemo(
    () => ({
      isOpen,
      openTemplateGeneration,
      closeTemplateGeneration,
      currentRequest,
      isPolling,
      isGenerating,
      isStaging,
      generationData,
      stageTemplateRequest,
      startTemplateGeneration,
    }),
    [
      isOpen,
      openTemplateGeneration,
      closeTemplateGeneration,
      currentRequest,
      isPolling,
      isGenerating,
      isStaging,
      generationData,
      stageTemplateRequest,
      startTemplateGeneration,
    ],
  )

  return (
    <TemplateGenerationContext.Provider value={value}>
      {children}
      {isOpen && <TemplateGenerationFlow isOpen={isOpen} onClose={closeTemplateGeneration} />}
      {showTemplateGenerationLoading && (
        <div className='absolute bottom-10 right-10'>
          <TemplateGenerationLoading onClose={onClose} />
        </div>
      )}
    </TemplateGenerationContext.Provider>
  )
}

export const useTemplateGeneration = () => {
  const context = useContext(TemplateGenerationContext)

  if (!context) {
    throw new Error('useTemplateGeneration must be used within a TemplateGenerationProvider')
  }

  return context
}
