import { ScrollArea } from '@valuecase/ui-components'
import { motion } from 'framer-motion'
import { useMemo, useRef } from 'react'
import { useTemplateGeneration } from '../context/TemplateGenerationContext'

export const TemplateGenerationReviewStage = () => {
  const { isStaging, currentRequest } = useTemplateGeneration()
  const pages = useMemo(() => currentRequest?.previewResponse?.preview ?? [], [currentRequest])
  const scrollRef = useRef<HTMLDivElement>(null)

  if (isStaging) {
    return (
      <div className='flex flex-col gap-6 h-full mt-8'>
        <div className='relative'>
          <ScrollArea
            ref={scrollRef}
            className='h-full gap-3 max-h-[400px] min-h-[400px] flex flex-col px-10'
          >
            <div className='flex flex-col gap-3'>
              {[1, 2, 3, 4].map((i) => (
                <motion.div
                  key={i}
                  className='flex flex-col gap-2 bg-gradient-to-r from-ai-s2-from via-ai-s2-via to-ai-s2-to p-2 rounded-lg animate-pulse'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: i * 0.1 }}
                >
                  <div className='h-4 w-24 bg-gradient-to-r from-ai-s1-from via-ai-s1-via to-ai-s1-to rounded' />
                  <div className='h-3 w-48 bg-gradient-to-r from-ai-s1-from via-ai-s1-via to-ai-s1-to rounded' />
                </motion.div>
              ))}
            </div>
          </ScrollArea>
        </div>
      </div>
    )
  }

  return (
    <div className='flex flex-col gap-6 h-full mt-8'>
      <div className='relative'>
        <ScrollArea
          ref={scrollRef}
          className='h-full gap-3 max-h-[400px] min-h-[400px] flex flex-col px-10'
        >
          <div className='flex flex-col gap-3'>
            {pages.map((page: { title: string; description: string }) => (
              <PagePreviewBlock key={page.title} {...page} />
            ))}
          </div>
        </ScrollArea>
      </div>
    </div>
  )
}

const PagePreviewBlock = ({ title, description }: { title: string; description: string }) => {
  return (
    <div className='flex flex-col gap-2 bg-white/50 p-2 rounded-lg'>
      <span className='text-sm font-semibold'>{title}</span>
      <span className='text-xs text-grey-s5'>{description}</span>
    </div>
  )
}
