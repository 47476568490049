type PromptCategory = {
  promptName: string
  description: string
  prompt: string
}

type UseCasePrompts = {
  [key: string]: PromptCategory[]
}

export const promptsByUseCase: UseCasePrompts = {
  sales: [
    {
      promptName: 'Outbound email',
      description: 'Simple email sequence template',
      prompt:
        "Create a simple template we can use in our outbound email sequences. It should include an intro to our product, which of their problems we solve, and who we work with. Let's also include a scheduler as well as more contact information. One page might be enough, but just to test, also add a page with more in-depth resources and links.",
    },
    {
      promptName: 'Enterprise sales',
      description: 'Complex enterprise sales process',
      prompt:
        "Create a template we can use in our complex enterprise sales process that runs 4-6 months. It must include a section in which we can explain our understanding of their status quo and goals, a business case, a mutual action plan from discovery to go live, including stakeholder management, as well as deep dive resources organized by stakeholder group. Add anything else you'd deem necessary for an enterprise sales process.",
    },
  ],
  onboarding: [
    {
      promptName: 'Low-touch',
      description: 'Fast self-service onboarding',
      prompt:
        'Create a template for a fast onboarding process of 15-30 days. The portal must include an onboarding plan that takes the customer by the hand through preparing the account, logging in, doing the setup steps, and launching the solution. In the preparation, customers need to fill out a form among other things. In the setup phase, a key step is to set up various integrations which differ from customer to customer. We want to share as many resources as possible so customers can self-serve. We want to reduce our effort to a minimum.',
    },
    {
      promptName: 'High-touch',
      description: 'Complex guided onboarding',
      prompt:
        "Create a template for a complex onboarding process of around 60 days. We start with a kickoff and then align the plan there. Depending on which products the customers choose, they get user training and setup plans. We want to shorten our onboarding process, track everything, and give the customer transparency during the onboarding. Also, we want to increase the self-sufficiency of our customers by enabling them to find all required resources in the portal. However, we'll still have meetings with them and do some handholding. They'll be personally in touch with our team.",
    },
  ],
  other: [
    {
      promptName: 'Plan an offsite',
      description: 'Internal event planning',
      prompt:
        'Create a template that we can use to plan our internal offsite and share all the offsite details with our team.',
    },
    {
      promptName: 'Employee onboarding',
      description: 'CS team onboarding process',
      prompt:
        'Create an employee onboarding process for our customer success team. The onboarding lasts 4-6 weeks and by the end of it, the customer success manager should have their first customer meetings. Include a nice welcome page, clear onboarding plans for each week, and resources for the employee.',
    },
  ],
}
