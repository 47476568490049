// Custom Icons
import Activity from './customIcons/Activity.svg?react'
import CalendarClearChecked from './customIcons/CalendarClearChecked.svg?react'
import ChevronLeft from './customIcons/ChevronLeft.svg?react'
import ChevronRight from './customIcons/ChevronRight.svg?react'
import Dropdown from './customIcons/Dropdown.svg?react'
import Edit from './customIcons/Edit.svg?react'
import EditOutline from './customIcons/EditOutline.svg?react'
import EngagementHigh from './customIcons/EngagementHigh.svg?react'
import EngagementLow from './customIcons/EngagementLow.svg?react'
import EngagementMedium from './customIcons/EngagementMedium.svg?react'
import Incognito from './customIcons/Incognito.svg?react'
import Link from './customIcons/Link.svg?react'
import PizzaSlice from './customIcons/PizzaSlice.svg?react'
import SquareImagePlaceholder from './customIcons/SquareImagePlaceholder.svg?react'
import TableSettings from './customIcons/TableSettings.svg?react'
import Telescope from './customIcons/Telescope.svg?react'
import ValuecaseLogo from './customIcons/ValuecaseLogo.svg?react'
import Rename from './customIcons/Rename.svg?react'

// Ion Icons
import Add from './ionIcons/add.svg?react'
import BrowsersOutline from './ionIcons/browsers-outline.svg?react'
import CalculatorOutline from './ionIcons/calculator-outline.svg?react'
import ChatbubbleEllipsesOutline from './ionIcons/chatbubble-ellipses-outline.svg?react'
import CheckmarkCircleOutline from './ionIcons/checkmark-circle-outline.svg?react'
import CheckmarkOutline from './ionIcons/checkmark-outline.svg?react'
import ChevronBack from './ionIcons/chevron-back.svg?react'
import ChevronDownOutline from './ionIcons/chevron-down-outline.svg?react'
import ChevronForward from './ionIcons/chevron-forward.svg?react'
import ChevronUpOutline from './ionIcons/chevron-up-outline.svg?react'
import ClipboardOutline from './ionIcons/clipboard-outline.svg?react'
import DocumentTextOutline from './ionIcons/document-text-outline.svg?react'
import DocumentsOutline from './ionIcons/documents-outline.svg?react'
import Flash from './ionIcons/flash.svg?react'
import HelpCircle from './ionIcons/help-circle.svg?react'
import List from './ionIcons/list.svg?react'
import NotificationsOutline from './ionIcons/notifications-outline.svg?react'
import ShareOutline from './ionIcons/share-outline.svg?react'
import SearchOutline from './ionIcons/search-outline.svg?react'
import Close from './ionIcons/close.svg?react'
import StatsChartOutline from './ionIcons/stats-chart-outline.svg?react'
import ArrowForward from './ionIcons/arrow-forward.svg?react'
import CompassFilled from './customIcons/CompassFilled.svg?react'
import ValuecasePictogramVariant1 from './customIcons/ValuecasePictogramVariant1.svg?react'
import ValuecasePictogramVariant2 from './customIcons/ValuecasePictogramVariant2.svg?react'
import NotificationsInnerStroke from './customIcons/NotificationsInnerStroke.svg?react'
import RemoveAutomation from './customIcons/RemoveAutomation.svg?react'
import VirtualMeeting from './customIcons/VirtualMeeting.svg?react'
import TeamWork from './customIcons/TeamWork.svg?react'
import SurfingChart from './customIcons/SurfingChart.svg?react'
import AiTemplatePreview from './customIcons/AiTemplatePreview.svg?react'
import AiSparkles from './customIcons/AiSparkles.svg?react'
import Minimize from './customIcons/Minimize.svg?react'
import { FC } from 'react'

export const CustomIcons: Record<string, FC> = {
  // c_ prefix is used to indicate that the icon is custom and not from Ionicons
  'c_calendar-clear-checked': CalendarClearChecked,
  'c_chevron-circle-left': ChevronLeft,
  'c_chevron-circle-right': ChevronRight,
  'c_edit-outline': EditOutline,
  'c_engagement-high': EngagementHigh,
  'c_engagement-low': EngagementLow,
  'c_engagement-medium': EngagementMedium,
  'c_pizza-slice': PizzaSlice,
  'c_square-image-placeholder': SquareImagePlaceholder,
  'c_table-settings': TableSettings,
  c_telescope: Telescope,
  c_activity: Activity,
  c_dropdown: Dropdown,
  c_edit: Edit,
  c_incognito: Incognito,
  c_link: Link,
  'c_compass-filled': CompassFilled,
  c_valuecase_logo: ValuecaseLogo,
  'c_pictogram-variant-1': ValuecasePictogramVariant1,
  'c_pictogram-variant-2': ValuecasePictogramVariant2,
  c_rename: Rename,
  'c_notifications-inner-stroke': NotificationsInnerStroke,
  c_remove_automation: RemoveAutomation,
  c_minimize: Minimize,
  'c_virtual-meeting': VirtualMeeting,
  'c_team-work': TeamWork,
  'c_surfing-chart': SurfingChart,
  'c_ai-template-preview': AiTemplatePreview,
  'c_ai-sparkles': AiSparkles,
}

export const BundledIonIcons: Record<string, FC> = {
  // Chrome extension needs the icons to be loaded from the bundle
  // To add new, download from https://unpkg.com/ionicons@7.1.0/dist/ionicons/svg/REPLACE_ICON_NAME.svg
  'browsers-outline': BrowsersOutline,
  'calculator-outline': CalculatorOutline,
  'chatbubble-ellipses-outline': ChatbubbleEllipsesOutline,
  'checkmark-circle-outline': CheckmarkCircleOutline,
  'checkmark-outline': CheckmarkOutline,
  'chevron-back': ChevronBack,
  'chevron-down': ChevronDownOutline, // It's the same icon as chevron-down-outline
  'chevron-down-outline': ChevronDownOutline,
  'chevron-forward': ChevronForward,
  'chevron-up-outline': ChevronUpOutline,
  'clipboard-outline': ClipboardOutline,
  'document-text-outline': DocumentTextOutline,
  'documents-outline': DocumentsOutline,
  'help-circle': HelpCircle,
  'notifications-outline': NotificationsOutline,
  'share-outline': ShareOutline,
  'stats-chart-outline': StatsChartOutline,
  'arrow-forward': ArrowForward,
  search: SearchOutline,
  add: Add,
  flash: Flash,
  list: List,
  close: Close,
}
