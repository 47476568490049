import { TTemplateGenerationRequestStatus } from '@valuecase/common'
import { Button2, IonIcon, Progress } from '@valuecase/ui-components'
import { useCallback, useMemo } from 'react'
import { useTemplateGeneration } from './context/TemplateGenerationContext'

const statusMessages = {
  [TTemplateGenerationRequestStatus.PREVIEW]: 'Initializing template generation...',
  [TTemplateGenerationRequestStatus.STARTED]: 'Analyzing your requirements...',
  [TTemplateGenerationRequestStatus.TEMPLATE_GENERATOR_RESPONSE_RECEIVED]:
    'Creating template structure...',
  [TTemplateGenerationRequestStatus.BLOCK_CREATOR_RESPONSE_RECEIVED]:
    'Validating template structure...',
  [TTemplateGenerationRequestStatus.OUTPUT_VALIDATION]: 'Creating template...',
  [TTemplateGenerationRequestStatus.COMPLETED]: 'Template generation completed!',
  [TTemplateGenerationRequestStatus.ERROR]: 'An error occurred during generation',
} as const

const getProgressValue = (status: TTemplateGenerationRequestStatus | undefined) => {
  switch (status) {
    case TTemplateGenerationRequestStatus.STARTED:
      return 10
    case TTemplateGenerationRequestStatus.TEMPLATE_GENERATOR_RESPONSE_RECEIVED:
      return 40
    case TTemplateGenerationRequestStatus.BLOCK_CREATOR_RESPONSE_RECEIVED:
      return 70
    case TTemplateGenerationRequestStatus.OUTPUT_VALIDATION:
      return 90
    case TTemplateGenerationRequestStatus.COMPLETED:
      return 100
    default:
      return 0
  }
}

const TemplateGenerationLoading = ({ onClose }: { onClose: () => void }) => {
  const { isGenerating, generationData, isPolling } = useTemplateGeneration()

  const progress = getProgressValue(generationData?.status)

  const getTemplateUrl = useCallback((templateRootNodeId: string): string => {
    const url = new URL(location.href)

    url.pathname = `/spaces/${templateRootNodeId}`
    url.search = ''

    return url.href
  }, [])

  const navigateToTemplate = useCallback(() => {
    if (generationData?.createdTemplateRootNodeId) {
      window.location.href = getTemplateUrl(generationData.createdTemplateRootNodeId)
    }
  }, [generationData?.createdTemplateRootNodeId, getTemplateUrl])

  const statusMessage = useMemo(() => {
    if (!generationData || !generationData.status) return 'Initializing template generation...'
    return statusMessages[generationData.status as keyof typeof statusMessages]
  }, [generationData])

  const isFinished = useMemo(() => {
    return (
      generationData?.status === TTemplateGenerationRequestStatus.COMPLETED &&
      generationData?.createdTemplateRootNodeId &&
      !isGenerating &&
      !isPolling
    )
  }, [generationData?.status, generationData?.createdTemplateRootNodeId, isGenerating, isPolling])

  const isError = useMemo(() => {
    return generationData?.status === TTemplateGenerationRequestStatus.ERROR && !isPolling
  }, [generationData?.status, isPolling])

  return (
    <div className='h-28 w-118 flex items-center justify-center p-6 bg-gradient-to-br from-ai-s1-from via-ai-s1-via to-ai-s1-to rounded-md shadow-md'>
      {isError && (
        <div className='absolute top-4 right-4 cursor-pointer' onClick={onClose}>
          <IonIcon name='close' className='text-grey-s5 w-4 h-4 hover:text-grey-s6' />
        </div>
      )}
      {!isFinished && !isError && isPolling && (
        <div className='flex flex-col w-full'>
          <span className='text-sm font-semibold text-grey-s6'>Creating Template</span>
          <div className='flex w-full'>
            <Progress className='w-full' variant={'ai'} value={progress} />{' '}
          </div>
          <span className='text-xs text-grey-s5'>{statusMessage}</span>
        </div>
      )}

      {isFinished && !isError && !isPolling && (
        <div className='flex w-full items-center justify-between'>
          <div className='flex items-center gap-2'>
            <IonIcon name='checkmark-circle' className='text-success-s5 w-6 h-6' />
            <span className='text-sm font-semibold text-grey-s6'>Your template is ready</span>
          </div>
          <Button2 onClick={navigateToTemplate}>Open Template</Button2>
        </div>
      )}

      {isError && (
        <div className='flex w-full items-center justify-start'>
          <IonIcon name='bug-outline' className='text-error-s5 w-6 h-6' />
          <span className='text-sm font-semibold text-grey-s6'>An error occurred</span>
        </div>
      )}
    </div>
  )
}

export default TemplateGenerationLoading
