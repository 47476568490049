import { spacesApi } from '@/api/spacesApi/spacesAPIclient'
import { TTemplateGenerationRequest, TTemplateGenerationRequestStatus } from '@valuecase/common'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo, useState } from 'react'
import { useNotifications } from '@valuecase/ui-components'

const fetchTemplateGenerationStatus = async (requestId: string | null) => {
  if (!requestId) {
    return
  }
  const response = await spacesApi.get<TTemplateGenerationRequest>(
    `/templates/generate/ai/${requestId}/status`,
  )
  return response.data
}

const shouldContinuePolling = (data: TTemplateGenerationRequest | undefined) => {
  if (!data) return true
  return ![
    TTemplateGenerationRequestStatus.COMPLETED,
    TTemplateGenerationRequestStatus.ERROR,
  ].includes(data.status)
}

export const usePollTemplateGenerationRequest = ({ requestId }: { requestId: string | null }) => {
  const [latestStatus, setLatestStatus] = useState<TTemplateGenerationRequestStatus | null>(null)
  const queryClient = useQueryClient()
  const { success, error: toastError } = useNotifications()
  const handleSuccess = useCallback(
    (data: TTemplateGenerationRequest) => {
      setLatestStatus(data?.status ?? null)
      if (data.status === TTemplateGenerationRequestStatus.COMPLETED) {
        queryClient.invalidateQueries(['readTemplates'])
        success('Template generation completed')
      } else if (data.status === TTemplateGenerationRequestStatus.ERROR) {
        toastError('Template generation failed')
      }
    },
    [queryClient, success, toastError],
  )

  const { data, error, isFetching } = useQuery({
    queryKey: ['templateGeneration', requestId],
    queryFn: () => fetchTemplateGenerationStatus(requestId),
    enabled: Boolean(requestId),
    onSuccess: handleSuccess,
    refetchInterval: (data) => (shouldContinuePolling(data) ? 1000 : false),
    retry: false,
  })

  const isPolling = useMemo(
    () =>
      isFetching ||
      (latestStatus !== TTemplateGenerationRequestStatus.COMPLETED &&
        latestStatus !== TTemplateGenerationRequestStatus.ERROR &&
        latestStatus !== null),
    [isFetching, latestStatus],
  )

  return {
    data,
    error,
    isPolling,
  }
}
