import { TGenerateTemplateDto, TTemplateGenerationRequest } from '@valuecase/common'
import { useMutation } from '@tanstack/react-query'
import { useNotifications } from '@valuecase/ui-components'
import { useCallback } from 'react'
import { spacesApi } from '@/api/spacesApi/spacesAPIclient'

interface StageTemplateRequestPayload {
  data: TGenerateTemplateDto
  templateGenerationRequestId?: string
}

export const useStageTemplateGenerationRequest = () => {
  const { error: notifyError } = useNotifications()

  const mutation = useMutation(
    (payload: StageTemplateRequestPayload) =>
      spacesApi.post<TTemplateGenerationRequest>('/templates/generate/ai/:id/upsert', payload),
    {
      onError: () => {
        notifyError('Error staging template generation request')
      },
    },
  )

  const stageTemplateRequest = useCallback(
    async (data: TGenerateTemplateDto, templateGenerationRequestId?: string) => {
      const response = await mutation.mutateAsync({
        data,
        templateGenerationRequestId,
      })
      return response.data
    },
    [mutation],
  )

  return {
    stageTemplateRequest,
    isStaging: mutation.isLoading,
    error: mutation.error,
    success: mutation.isSuccess,
  }
}
