import { spacesApi } from '@/api/spacesApi/spacesAPIclient'
import { useMutation } from '@tanstack/react-query'
import { TTemplateGenerationRequest } from '@valuecase/common'
import { useNotifications } from '@valuecase/ui-components'
import { useCallback } from 'react'

interface GenerateTemplatePayload {
  useCase: 'sales' | 'onboarding' | 'other'
  context?: string
  companyWebsite: string
}

export const useGenerateTemplate = (requestId: string | null) => {
  const { error: notifyError } = useNotifications()

  const mutation = useMutation(
    (payload: GenerateTemplatePayload) =>
      spacesApi.post<TTemplateGenerationRequest>(`/templates/generate/ai/${requestId}/confirm`, {
        data: payload,
      }),
    {
      onError: () => {
        notifyError('Error creating template generation request')
      },
    },
  )

  const generateTemplate = useCallback(
    async (payload: GenerateTemplatePayload) => {
      const response = await mutation.mutateAsync(payload)
      return response.data
    },
    [mutation],
  )

  return {
    generateTemplate,
    isGenerating: mutation.isLoading,
    error: mutation.error,
    success: mutation.isSuccess,
  }
}
